














import TableTabs from './TableTabs';
export default TableTabs;
