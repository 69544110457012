import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CheckinTabs extends Vue {
  @Prop()
  tabSelected: string = '';

  @Prop()
  tabs: string[] = [];

  selectTab(selectedTab: string) {
    this.$emit('tabChanged', selectedTab);
  }
}
